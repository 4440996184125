html {
  min-height: 440px;
  height: 100%;
}

body {
  background: var(--matter-bright-blue-light);
  background-size: auto;
  height: 100%;
}
// Uncomment for debugging layout
// * {
//   outline: 1px dashed red;
// }

#root {
  height: 100vh;
}

#okta-sign-in {
  &.auth-container{
    &.main-container {
      *,
      input[type=text],
      input[type=button],
      input[type=submit]  {
        font-family: 'Haffer XH', 'Helvetica Neue', Arial, Verdana, sans-serif;
      }

      border: 0;
      box-shadow: none;
      margin: 0;
      color: white;
      height: 100%;

      .infobox {
        color: var(--matter-dark-blue);
      }

      a.link,
      a.registration-link {
        color: var(--matter-bright-blue);
        text-decoration: underline;
        &:hover {
          color: var(--matter-bright-blue-light);
        }
      }

      .auth-content {
        background: var(--matter-dark-blue);
        border: none;
        height: 100%;

        &-inner,
        .idp-discovery,
        .primary-auth {
          height: 100%;
        }

        .idp-discovery,
        .primary-auth {
          display: flex;
          flex-direction: column;

          .registration-container {
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
          }
        }
      }

      .button-primary {
        height: 60px;
        width: 100%;
        color: var(--matter-dark-blue);
        border-color: var(--matter-bright-blue);
        text-shadow: none;
        box-shadow: none;
        padding: 8px 30px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 30px;
        line-height: 42px;

        &:hover {
          border-color: var(--matter-bright-blue-light);
          background: var(--matter-bright-blue-light);
        }
        &:active {
          border-color: var(--matter-bright-blue);
          background:var(--matter-bright-blue);
        }
      }

      input[type=text],
      .focused-input, 
      .link.help:focus,
      input[type=radio]:focus+label,
      input[type=text]:focus,
      input[type=submit]:focus {
        box-shadow: none;
      }

      .o-form-content {
        padding-top: 12px;
      }

      .o-form-button-bar,
      .o-form-fieldset.margin-btm-30,
      .o-form-fieldset.margin-btm-5 {
        margin-bottom: 12px;
        padding: 0;
      }

      .okta-form-input-field {
        border-radius: 30px;
        padding: 8px 19px;
        border: none;
        height: 60px;

        &:not(.o-form-disabled):hover {
          background: var(--matter-gray-1);
          border: none;
        }

        input[type=text],
        input[type=password] {
          padding: 0;
          box-shadow: none;
          background-color: inherit;
          color: var(--matter-dark-blue);

          &::placeholder {
            color: var(--matter-gray-4);
          }
          &[disabled]::placeholder {
            color: var(--matter-gray-3);
          }
        }
      }

      input:-webkit-autofill, 
      input:-webkit-autofill:focus, 
      input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
      }

      input:-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 30px var(--matter-gray-1) inset !important;
      }

      .okta-form-input-error {
        color: var(--matter-red);
        padding: 12px 0 0;
        .icon {
          display: none;
        }
      }
    }

    .content-container {
      border-top: none;
      padding: 0;
    }

    .help-links {
      display: flex;
      justify-content: space-between;

      li {
        margin: 0;
      }
    }
    .auth-footer {
      margin-top: 30px;

      &.footer-back-link {
        text-align: center;
      }
    }

    .auth-header, h2, h3, .o-form-label {
      display: none;
    }
  }

}

#globalHeader .nav__selector {
  display: none; }

#globalHeader .nav__actions__permissions {
  display: none; }

#globalHeader .nav__user-info {
  top: 60px; }

#globalHeader .nav__user-info:after {
  right: 20px; }

#globalHeader .nav__actions__search {
  display: none; }

#globalHeader .nav__product {
  cursor: default;
  pointer-events: none; }

.nav__actions__flyout {
  display: none; }

.okta .nav__actions__signup {
  display: none; }

.okta .nav__actions__login {
  display: none; }

.g-recaptcha {
  display: flex;
  justify-content: center; }

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important; }

.grecaptcha-badge:hover {
  width: 256px !important; }

//Overriding css to hide hamburger, search and the account switcher on login page

#globalHeader {
  .nav__selector {
    display: none
  }

  .nav__actions__permissions {
    display: none
  }

  .nav__user-info {
    top: 60px
  }

  .nav__user-info:after {
    right: 20px;
  }

  .nav__actions__search{
    display:none;
  }

  .nav__product {
    cursor: default;
    pointer-events: none;
  }
} 

.nav__actions__flyout{
  display:none;
}

.okta {
  .nav__actions__signup {
    display: none;
  }
  .nav__actions__login {
    display: none;
  }
}

.g-recaptcha {
  display: flex;
  justify-content: center;
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}
